// Node Modules
import css from "styled-jsx/css";

export default function getAutocallModalStyles() {
  return css.resolve`

      .autocall-modal-container {
        display: flex;
        flex-direction: column;
      }

      .modal-content {
        height: 90rem;
      }

      .button-container {
        bottom: 11%;
        margin-bottom: 25px;
      }

      .close-btn-container {
        display: none;
      }

      .footer-text {
        bottom: -8%;
        font-size: 28px;
        position: absolute;
      }

      .modal {
        bottom: 7%;
      }

      .modal-body {
        min-height: 75%;
      }
      
    `;
}
