// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unused-vars */
// Components
import AttractionLoop from "@/src/components/attraction-loop/AttractionLoop";
import AutocallModal from "@/gc/modals/autocall-modal/AutocallModal";
import HoursAndInformation from "@/components/splash-screen/sub-components/hours-and-information/HoursAndInformation";
import KioskApp from "@/src/KioskApp";
import SplashScreenBody from "@/src/components/splash-screen/SplashScreenBody";
import SupportModal from "@/gc/modals/support-modal/SupportModal";

// Constants
import { CustomerSupportFeature } from "@/enums/optimizely/CustomerSupportFeature";
import dataLayerTypes from "@/scripts/constant-types/google-analytics/dataLayerTypes";

// Context
import { useCallContext } from "@/context/CallContext";
import { useCustomerDetectionContext } from "@/context/CustomerDetectionContext";
import { useCustomerSupportContext } from "@/context/CustomerSupportContext";
import {
  featureNames,
  useFeatureToggleContext
} from "@/context/FeaturesToggleContext";

import { useDeviceInformationContext } from "@/context/device-information/DeviceInformationContext";
import { useKioskUserSessionContext } from "@/context/KioskUserSessionContext";
import { useSchedulesContext } from "@/context/SchedulesContext";
import { useStoreDetailsContext } from "@/context/store-details/StoreDetailsContext";
import { useVideoCallContext } from "@/context/VideoCallContext";

// Hooks
import useSessionStorage from "@/hooks/useSessionStorage";

// Node Modules
import { useRouter } from "next/router";
import { v4 as uuidv4 } from "uuid";
import {
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";

// Scripts
import { kioskPageContentEntryIds } from "@/scripts/constant-types/pageContent";
import {
  accessibilitySizeOptions,
  resetZoom
} from "@/scripts/accessibilityHelper";
import {
  getNscContentfulDefaultHours,
  setFormattedHoursOfOperationInLocalStorage,
} from "@/scripts/hoursOfOperationHelper";

// Services
import dataLayerService from "@/services/dataLayerService";
import splashScreenService from "@/components/services/splashScreenService";
import userSessionService from "@/components/services/userSessionService";

// Types
import { IAttractionLoopInfoFields } from "@/contentful-types";
import IIndexProps from "@/interfaces/pages/IIndexProps";
import { IStoreAddress } from "@/interfaces/splash-screen";

// Utils
import { callStatuses } from "@/scripts/constant-types/voice/twilioConstants";
import {
  getAttractionLoopVideoInformation,
  useSubPageNavigation
} from "@/scripts/kioskUtil";

const Index = ({
  content,
}: IIndexProps) => {
  const {
    currentPage,
    handleBack,
    handleForward,
    resetCurrentPage,
  } = useSubPageNavigation(2, 1);

  const
    {
      small,
    } = accessibilitySizeOptions;

  const {
    featureToggleList,
  } = useFeatureToggleContext();

  const router = useRouter();
  const queryParamDisplayAttractionLoop: boolean = router?.query.displayAttractionLoop == "true";
  const featureToggle = featureToggleList.find(item => item.featureName == featureNames.flex);
  const isFlexEnabled: boolean = !!featureToggle && featureToggle.isEnabled;

  const [hasAvailableInventory, setHasAvailableInventory] = useState(true);
  const [address, setAddress] = useState<IStoreAddress>();
  const [displayNoInventoryScreen, setDisplayNoInventoryScreen] = useState(false);
  const [showAutoCallModal, setShowAutoCallModal] = useState(false);

  const [shouldRunCallFromUserEngaged, setShouldRunCallFromUserEngaged] = useState(false);
  const isSplashScreen = currentPage === 1 && !displayNoInventoryScreen;
  const isHomeButtonEnabled = currentPage != 1 || displayNoInventoryScreen;
  const [showPinchScreenTutorial, setShowPinchScreenTutorial] = useSessionStorage("pinchScreenTutorial", true, false);
  const [sessionFontSize, setSessionFontSize] = useSessionStorage(
    "fontSizeZoomValue",
    1,
    false
  );

  const {
    buttonText,
    descriptionText,
    footerText,
    title,
  } = content.autoCallModal?.fields;

  const {
    deviceInformation,
  } = useDeviceInformationContext();

  const {
    setStoreDetails,
  } = useStoreDetailsContext();
  
  const {
    shouldRunInteractionCall,
    setIsCustomerInteractingWithTheScreen,
  } = useCustomerDetectionContext();

  const {
    isNscCurrentlyOpen,
    isStaffingCurrentlyOpen,
  } = useSchedulesContext();

  const {
    showAttractionLoop,
    sessionUserId,
    setShowAttractionLoop,
    setSessionUserId,
  } = useKioskUserSessionContext();

  const {
    setSchedules,
  } = useSchedulesContext();

  const {
    customerSupportFeature,
    setHasAttractionLoopJustBeenClosed,
    runCustomerSupportFeature,
    startCallFunctionRef,
  } = useCustomerSupportContext();

  const {
    audioElement: audioRef,
  } = useVideoCallContext();

  const makeInteractionCall = () => {
    dataLayerService.pushEvent({
      event: dataLayerTypes.events.interactionCall,
    });

    startCallFunctionRef.current();
  };

  const {
    callState,
  } = useCallContext();

  const {
    callState: videoCallState,
    isIncomingCall,
  } = useVideoCallContext();

  useEffect(() => {
    if (customerSupportFeature === CustomerSupportFeature.InteractionCall
      && ((runCustomerSupportFeature && !isStaffingCurrentlyOpen) || (shouldRunCallFromUserEngaged && showAttractionLoop && !isStaffingCurrentlyOpen))
      && !(runCustomerSupportFeature && shouldRunCallFromUserEngaged)) {
      setTimeout(() => makeInteractionCall(), 1000);

      if (shouldRunCallFromUserEngaged) {
        closeAttractionLoop();
      }
    }
  }, [
    isStaffingCurrentlyOpen,
    shouldRunCallFromUserEngaged,
    runCustomerSupportFeature,
  ]);

  useEffect(() => {
    setShouldRunCallFromUserEngaged(shouldRunInteractionCall && isNscCurrentlyOpen && !isStaffingCurrentlyOpen);
  }, [
    customerSupportFeature,
    shouldRunInteractionCall,
    isNscCurrentlyOpen,
    isStaffingCurrentlyOpen,
  ]);

  const [attractionLoopVideoInformation, setAttractionLoopVideoInformation] = useState<IAttractionLoopInfoFields>();

  useEffect(() => {
    const bestAttractionLoopVideoInformation = getAttractionLoopVideoInformation(content, deviceInformation);
    setAttractionLoopVideoInformation(bestAttractionLoopVideoInformation)

    if (queryParamDisplayAttractionLoop) {
      setShowAttractionLoop(queryParamDisplayAttractionLoop);
    }
  }, []);

  const getHandleBackFunctionality = () => {
    if (isSplashScreen) {
      handleForward();
    } else if (displayNoInventoryScreen) {
      setDisplayNoInventoryScreen(false);
    } else {
      handleBack();
    }
  };

  const showSplashScreen = () => {
    resetCurrentPage();
    setDisplayNoInventoryScreen(false);
  };

  useEffect(() => {
    if (showAttractionLoop) {
      resetCurrentPage();
      endUserSession();
      setIsCustomerInteractingWithTheScreen(false);
    }
  }, [showAttractionLoop]);

  useEffect(() => {
    setCustomDimensionsForAnalytics();
    checkUnitsAvailability();
  }, [deviceInformation]);

  useEffect(() => {
    const isAudioCallStateActive: boolean = callState?.callStatus && callState?.callStatus === callStatuses.connecting;
    const isVideoCallStateActive: boolean = videoCallState?.callStatus && videoCallState?.callStatus === callStatuses.connecting;

    if (showAutoCallModal && !isAudioCallStateActive && !isVideoCallStateActive) {
      setShowAutoCallModal(false);
    }

    if (isVideoCallStateActive && !isIncomingCall) {
      setShowAutoCallModal(true);
    }
  }, [callState, videoCallState]);

  const closeAttractionLoop = async (event?: KeyboardEvent<HTMLDivElement>): Promise<void> => {
    if (!attractionLoopVideoInformation.isClosable || event?.key == "Shift") {
      return;
    }

    if (router?.query.displayAttractionLoop) {
      router.replace("/", undefined, {
        shallow: true,
      });
    }

    setShowAttractionLoop(false);
    setHasAttractionLoopJustBeenClosed(true);
    await checkUnitsAvailability();
    startUserSession();
  };

  const reduceDialingVolume = (): void => {
    if (audioRef.current) {
      audioRef.current.volume = parseFloat(process.env.KIOSK_DIALING_REDUCED_VOLUME_LEVEL);
    }
  };

  const closeAutoCallModal = (): void => {
    reduceDialingVolume();
    setShowAutoCallModal(false);
  };

  const startUserSession = () => {
    const userId: string = uuidv4();
    setSessionUserId(userId);
    userSessionService.startSession(deviceInformation.ozStoreId, userId);
    setShowPinchScreenTutorial(true);
    resetFontSize();
  };

  const resetFontSize = () => {
    const findBodyElement = setTimeout(() => {
      const childrenBodyElement: HTMLElement = document.getElementById("children-body");
      if (childrenBodyElement) {
        resetZoom(document);
        setSessionFontSize(small.zoom);
        clearInterval(findBodyElement);
      }
    }, 1)
  };

  const endUserSession = () => {
    userSessionService.endSession(deviceInformation.ozStoreId, sessionUserId);
    setSessionUserId("");
  };

  const checkUnitsAvailability = async (): Promise<void> => {
    const isOzStoreIdNotNull = !!deviceInformation && !!deviceInformation.ozStoreId;
    if (isOzStoreIdNotNull) {
      const getUnitsAvailabilityForStore = async (ozStoreId: string): Promise<void> => {
        const result = await splashScreenService.getStoreDetails(ozStoreId);

        const {
          address,
          adminFee,
          hasAvailableInventory,
          hoursOfOperation,
        } = result;

        setStoreDetails(address, adminFee, hasAvailableInventory, hoursOfOperation);
        setAddress(address);

        setHasAvailableInventory(hasAvailableInventory);

        const nscHoursOfOperation = getNscContentfulDefaultHours(content.defaultStoreSchedule);
        const allFourSchedules = [...hoursOfOperation, nscHoursOfOperation];

        setSchedules(allFourSchedules);
        setFormattedHoursOfOperationInLocalStorage(
          allFourSchedules,
          content.defaultStoreSchedule,
          content.hoursAndInformation.fields?.twentyFourHoursHeading);
      };

      getUnitsAvailabilityForStore(deviceInformation.ozStoreId);
    }
  };

  const setCustomDimensionsForAnalytics = () => {
    const interval = setInterval(() => {
      const ozStoreId: string = deviceInformation?.ozStoreId;
      if (ozStoreId) {
        dataLayerService.setCustomDimensions(ozStoreId);
        clearInterval(interval);
      }
    }, 1000);
  };

  return (
    showAttractionLoop && attractionLoopVideoInformation
      ? (
        <AttractionLoop
          closeAttractionLoop={closeAttractionLoop}
          videoSrc={attractionLoopVideoInformation?.video.fields.file.url}
        />
      )
      : (
        <KioskApp
          content={content}
          disableBackButton={isSplashScreen}
          handleBack={() => getHandleBackFunctionality()}
          headTitle="Splash Screen"
          homeButtonOnClick={showSplashScreen}
          isHomeButtonEnabled={isHomeButtonEnabled}
          passiveTimerFunctionality={currentPage === 1
            ? (() => setShowAttractionLoop(true))
            : undefined}
          shouldShowDiagnosticsModal={currentPage === 2}
        >
          {runCustomerSupportFeature && customerSupportFeature === CustomerSupportFeature.TADModal && (
            <SupportModal
              content={content}
            />
          )}

          {currentPage === 1 && (
            <SplashScreenBody
              address={address}
              content={content}
              displayNoInventoryScreen={displayNoInventoryScreen}
              handleForward={handleForward}
              hasAvailableInventory={hasAvailableInventory}
              setDisplayNoInventoryScreen={setDisplayNoInventoryScreen}
            />
          )}
          {currentPage === 2 && (
            <HoursAndInformation
              content={content}
            />
          )}
          {
            showAutoCallModal && (
              <AutocallModal
                buttonText={buttonText}
                footerText={footerText}
                message={descriptionText}
                title={title}
                onClick={() => closeAutoCallModal()}
              />
            )
          }
        </KioskApp>
      )
  );
};

Index.pageContentEntryId = kioskPageContentEntryIds.splashScreen;

export default Index;
