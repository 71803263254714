// Constants
import dataLayerTypes from "@/scripts/constant-types/google-analytics/dataLayerTypes";

// Context
import { useCustomerSupportContext } from "@/context/CustomerSupportContext";

// Node Modules
import FontAwesomeIcon from "@/gc/icon/FontAwesomeIcon";
import { faUserHeadset } from "@fortawesome/pro-light-svg-icons/faUserHeadset";

// Services
import dataLayerService from "@/services/dataLayerService";

// Styles
import getSupportModalStyles from "./StylesSupportModal";

// Types
import { ISupportModalProps } from "@/interfaces/global-components/modals/ISupportModalProps";

const SupportModal = ({
  content,
}: ISupportModalProps) => {
  const {
    className,
    styles,
  } = getSupportModalStyles();

  const {
    actionButtonText,
    descriptionText,
    dismissButtonText,
    title,
  } = content.supportModal?.fields;

  const {
    resetHasAttractionLoopJustBeenClosed,
    setRunCustomerSupportFeature,
    startCallFunctionRef,
  } = useCustomerSupportContext();

  const dismissSupportModal = (): void => {
    setRunCustomerSupportFeature(false);
    resetHasAttractionLoopJustBeenClosed();
  };

  const onActionClick = () => {
    dataLayerService.pushEvent({
      event: dataLayerTypes.events.supportModalOnActionClick,
    });

    startCallFunctionRef.current();
    dismissSupportModal();
  }

  const onDismissClick = () => {
    dataLayerService.pushEvent({
      event: dataLayerTypes.events.supportModalOnDismissClick,
    });

    dismissSupportModal();
  }

  return (
    <>

      <div
        className={`modal-content ${className}`}
        role="support-modal"
      >
        <div className={`main-content ${className}`}>
          <div
            className={`icon-section ${className}`}
          >
            <FontAwesomeIcon
              className={`icon ${className}`}
              icon={faUserHeadset}
            />
          </div>
          <div className={`text-info-section ${className}`}>
            <h3 className={`${className}`}>{title}</h3>
            <p className={`${className}`}>{descriptionText}</p>
          </div>

          <div className={`button-section ${className}`}>

            <button
              className={`support-modal-button ${className}`}
              data-testid="accept-call-agent"
              onClick={onActionClick}
            >{actionButtonText}</button>

            <button
              className={`support-modal-button ${className}`}
              data-testid="decline-call-agent"
              onClick={onDismissClick}
            >{dismissButtonText}</button>
          </div>

        </div>
      </div>
      {styles}
    </>
  );
};

export default SupportModal;
